html,
body {
    min-width: 320px;
    min-height: 100vh;
    position: relative;
    overflow-x: hidden;
}

.no-scroll {
    position: relative;
    overflow: hidden;
}
